import React from 'react';

export const NetlifyIcon = props => (
  <svg width={12} height={12} fill="none" viewBox="0 0 12 12" {...props}>
    <path
      fill="#4CAA9F"
      d="M.474 7.144a1.619 1.619 0 010-2.288L4.856.474a1.619 1.619 0 012.288 0l4.382 4.382a1.619 1.619 0 010 2.288l-4.382 4.382a1.619 1.619 0 01-2.288 0L.474 7.144z"
    />
  </svg>
);

export const PrettierIcon = props => (
  <svg width={10} height={11} fill="none" viewBox="0 0 10 11" {...props}>
    <path
      fill="#56B3B4"
      d="M8.486 2.057H7.97a.257.257 0 100 .514h.515a.257.257 0 100-.514z"
    />
    <path
      fill="#EA5E5E"
      d="M2.829 10.286H.257a.257.257 0 000 .514H2.83a.257.257 0 100-.514z"
    />
    <path
      fill="#BF85BF"
      d="M7.971 6.171H6.43a.257.257 0 100 .515H7.97a.257.257 0 000-.515z"
    />
    <path
      fill="#EA5E5E"
      d="M5.4 6.171H3.343a.257.257 0 100 .515H5.4a.257.257 0 100-.515z"
    />
    <path
      fill="#56B3B4"
      d="M2.314 6.171H.257a.257.257 0 100 .515h2.057a.257.257 0 100-.515z"
    />
    <path
      fill="#BF85BF"
      d="M2.829 8.229H.257a.257.257 0 100 .514H2.83a.257.257 0 100-.514zM2.829 4.114H.257a.257.257 0 100 .515H2.83a.257.257 0 100-.515z"
    />
    <path
      fill="#F7BA3E"
      d="M7.971 1.028H2.828a.257.257 0 000 .515h5.143a.257.257 0 000-.515z"
    />
    <path
      fill="#EA5E5E"
      d="M1.8 1.028H.257a.257.257 0 000 .515H1.8a.257.257 0 000-.515z"
    />
    <path
      fill="#F7BA3E"
      d="M2.829 9.257h-.515a.257.257 0 000 .515h.515a.257.257 0 100-.515z"
    />
    <path
      fill="#56B3B4"
      d="M2.829 3.086h-.515a.257.257 0 100 .514h.515a.257.257 0 100-.514zM1.286 9.257H.257a.257.257 0 000 .515h1.029a.257.257 0 100-.515z"
    />
    <path
      fill="#F7BA3E"
      d="M1.286 3.086H.257a.257.257 0 100 .514h1.029a.257.257 0 100-.514z"
    />
    <path
      fill="#56B3B4"
      d="M8.486 5.143H4.37a.257.257 0 100 .514h4.115a.257.257 0 100-.514z"
    />
    <path
      fill="#F7BA3E"
      d="M3.343 5.143H1.8a.257.257 0 100 .514h1.543a.257.257 0 100-.514z"
    />
    <path
      fill="#EA5E5E"
      d="M.771 5.143H.257a.257.257 0 100 .514h.514a.257.257 0 100-.514z"
    />
    <path
      fill="#BF85BF"
      d="M6.943 2.057H4.886a.257.257 0 100 .514h2.057a.257.257 0 100-.514z"
    />
    <path
      fill="#56B3B4"
      d="M3.857 2.057h-3.6a.257.257 0 000 .514h3.6a.257.257 0 100-.514z"
    />
    <path
      fill="#BF85BF"
      d="M.771 7.2H.257a.257.257 0 100 .514h.514a.257.257 0 000-.514z"
    />
    <path
      fill="#EA5E5E"
      d="M9 3.086H6.429a.257.257 0 100 .514H9a.257.257 0 100-.514z"
    />
    <path
      fill="#F7BA3E"
      d="M9 4.114H6.429a.257.257 0 100 .515H9a.257.257 0 100-.515z"
    />
    <path
      fill="#56B3B4"
      d="M6.429 0H.257a.257.257 0 000 .514H6.43a.257.257 0 100-.514z"
    />
  </svg>
);

export const NPMIcon = props => (
  <svg width={17} height={16} fill="none" viewBox="0 0 17 16" {...props}>
    <path
      fill="#FF453A"
      d="M.667 16V0h16.172v16H.668zM3.708 2.998v9.98h5.097V5.079h2.97v7.9h2.022V3H3.708z"
    />
  </svg>
);

export const VercelIcon = props => (
  <svg width={12} height={10} fill="none" viewBox="0 0 12 10" {...props}>
    <path fill="#fff" d="M6 0l6 10H0L6 0z" />
  </svg>
);

export const JSIcon = props => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth={1}>
      <g id="configJS" fillRule="nonzero">
        <path id="Path" fill="#F7DF1E" d="M0 0L16 0 16 16 0 16z" />
        <g id="Group" fill="#000" transform="translate(4 7)">
          <path
            id="Path"
            d="M.207 6.37l1.224-.74c.237.418.452.773.967.773.494 0 .806-.194.806-.945V.345h1.503V5.48c0 1.557-.913 2.266-2.244 2.266C1.26 7.745.56 7.122.207 6.37M5.524 6.21l1.224-.71c.322.527.741.913 1.482.913.623 0 1.02-.311 1.02-.74 0-.516-.407-.699-1.095-1l-.376-.16C6.695 4.05 5.975 3.47 5.975 2.245c0-1.127.86-1.987 2.202-1.987.956 0 1.643.333 2.137 1.203l-1.17.752c-.258-.462-.538-.644-.967-.644-.44 0-.72.279-.72.644 0 .451.28.634.924.913l.376.161c1.278.548 1.997 1.106 1.997 2.363 0 1.353-1.063 2.094-2.491 2.094-1.397 0-2.299-.666-2.739-1.536"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const CodeSandboxIcon = props => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth={1}>
      <g id="configCodesandbox">
        <path
          id="Path"
          fill="#000"
          fillRule="nonzero"
          d="M0 1a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1H1a1 1 0 01-1-1V1z"
        />
        <path
          id="Shape"
          fill="#999"
          d="M7.82 8.532v5.418c.095 0 .157-.02.241-.069l4.339-2.48c.171-.097.241-.25.241-.447V5.927c0-.099-.02-.158-.069-.241L7.959 8.293a.275.275 0 00-.139.239zm2.41 3.11c0 .139-.051.208-.172.276l-1.446.827c-.103.069-.24.034-.24-.104V8.957c0-.098.086-.226.171-.275l3.306-1.894c.092-.053.172.032.172.138v1.962a.265.265 0 01-.138.241l-1.48.792c-.09.048-.172.14-.172.241v1.48z"
        />
        <path
          id="Shape"
          fill="#F2F2F2"
          d="M3 10.954V5.927c0-.198.104-.384.276-.482l4.2-2.376A.839.839 0 017.821 3c.103 0 .262.026.344.069l4.166 2.376c.083.048.195.16.241.24L7.958 8.304a.278.278 0 00-.137.24v5.407a.555.555 0 01-.276-.07L3.31 11.437c-.172-.098-.31-.284-.31-.482zm.62-4.029v1.963c0 .138.034.207.172.276l1.446.826a.283.283 0 01.172.275v1.378c0 .137.035.206.172.275l1.447.827c.137.068.24.034.24-.104V8.957a.284.284 0 00-.171-.276L3.86 6.822c-.104-.069-.241-.034-.241.103zm5.647-2.341l-1.274.723a.313.313 0 01-.345 0l-1.274-.723a.285.285 0 00-.275 0l-1.584.895c-.138.069-.138.207 0 .276L7.683 7.58c.085.048.19.048.275 0l3.168-1.825c.103-.07.138-.207 0-.276l-1.584-.895a.285.285 0 00-.275 0z"
        />
      </g>
    </g>
  </svg>
);
