import React from 'react';

export const ReloadIcon = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1064 9.14286C13.5833 12.3547 10.8928 14.75 7.72414 14.75C4.18955 14.75 1.25 11.7696 1.25 8C1.25 4.23037 4.18955 1.25 7.72414 1.25C10.3759 1.25 12.6927 2.92745 13.6846 5.35714L11.0345 5.35714V6.85714H14.1064H14.3448L14.3448 6.85714L15.3701 6.85714H15.8448V1.14286L14.3448 1.14286V3.87703C12.9932 1.5538 10.5339 0 7.72414 0C3.45821 0 0 3.58172 0 8C0 12.4183 3.45821 16 7.72414 16C11.6154 16 14.8346 13.0198 15.3701 9.14286H14.1064Z"
      fill="currentColor"
    />
  </svg>
);

export const LikeIcon = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.88809 15C21.8973 7.50193 14.9691 -2.70201 7.8881 2.35226C1.1128 -2.70201 -5.8154 7.50192 7.88809 15Z"
      fill="currentColor"
    />
  </svg>
);

export const EmbedIcon = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6 10.4125V13.6937L16 8.0875L9.6 2.5V5.6875C3.38 6.49375 0.9 10.4875 0 14.5C2.22 11.6875 5.16 10.4125 9.6 10.4125V10.4125Z"
      fill="currentColor"
    />
  </svg>
);

export const ForkIcon = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 2C14 0.88 13.12 0 12 0C10.88 0 10 0.88 10 2C10 2.72 10.4 3.4 11 3.72V5.4L8 8.72L5 5.4V3.72C5.6 3.4 6 2.76 6 2C6 0.88 5.12 0 4 0C2.88 0 2 0.88 2 2C2 2.72 2.4 3.4 3 3.72V6.16L7 10.56V12.28C6.4 12.64 6 13.28 6 14C6 15.12 6.88 16 8 16C9.12 16 10 15.12 10 14C10 13.28 9.6 12.64 9 12.28V10.56L13 6.16V3.72C13.6 3.4 14 2.76 14 2ZM4 1C4.52 1 4.96 1.44 4.96 2C4.96 2.56 4.52 2.96 4 2.96C3.48 2.96 3.04 2.52 3.04 2C3.04 1.48 3.48 1 4 1ZM8 14.92C7.48 14.92 7.04 14.52 7.04 13.96C7.04 13.4 7.48 13 8 13C8.52 13 8.96 13.44 8.96 13.96C8.96 14.48 8.52 14.92 8 14.92ZM12 1C12.52 1 12.96 1.44 12.96 2C12.96 2.56 12.52 2.96 12 2.96C11.48 2.96 11.04 2.52 11.04 2C11.04 1.48 11.48 1 12 1Z"
      fill="currentColor"
    />
  </svg>
);

export const PreferenceIcon = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00283 7.09842e-08L6.5413 0L6.17112 1.48071C5.56427 1.6506 4.99188 1.90294 4.46677 2.2249L3.21339 1.47287L1.47283 3.21344L2.22487 4.46684C1.86015 5.06173 1.58477 5.7173 1.41739 6.41489L0 6.76924L8.51808e-07 9.23078L1.41741 9.58513C1.58478 10.2827 1.86017 10.9383 2.22489 11.5332L1.47285 12.7866L3.21341 14.5272L4.46681 13.7751C5.0617 14.1398 5.71728 14.4152 6.41487 14.5826L6.76922 16H9.23075L9.58509 14.5826C10.2827 14.4152 10.9383 14.1398 11.5332 13.7751L12.7866 14.5271L14.5271 12.7866L13.7751 11.5332C14.1398 10.9383 14.4152 10.2827 14.5826 9.58511L16 9.23077L16 6.76923L14.5826 6.41488C14.4152 5.71728 14.1399 5.06169 13.7751 4.4668L14.5272 3.21342L12.7866 1.47285L11.5332 2.22487C10.8702 1.81838 10.1318 1.52285 9.34387 1.36415L9.00283 7.09842e-08ZM8.00001 11.4286C9.89355 11.4286 11.4286 9.89354 11.4286 7.99999C11.4286 6.10644 9.89355 4.57142 8.00001 4.57142C6.10646 4.57142 4.57144 6.10644 4.57144 7.99999C4.57144 9.89354 6.10646 11.4286 8.00001 11.4286Z"
      fill="currentColor"
    />
  </svg>
);

export const MoreMenuIcon = props => (
  <svg
    width="4"
    height="13"
    viewBox="0 0 4 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.16681 1.83337C3.16681 2.56975 2.56985 3.1667 1.83348 3.1667C1.0971 3.1667 0.500153 2.56975 0.500153 1.83337C0.500153 1.097 1.0971 0.500046 1.83348 0.500046C2.56985 0.500046 3.16681 1.097 3.16681 1.83337ZM3.16681 6.16653C3.16681 6.9029 2.56985 7.49986 1.83348 7.49986C1.0971 7.49986 0.500153 6.9029 0.500153 6.16653C0.500153 5.43015 1.0971 4.8332 1.83348 4.8332C2.56985 4.8332 3.16681 5.43015 3.16681 6.16653ZM1.83348 12.1666C2.56985 12.1666 3.16681 11.5697 3.16681 10.8333C3.16681 10.0969 2.56985 9.49995 1.83348 9.49995C1.0971 9.49995 0.500153 10.0969 0.500153 10.8333C0.500153 11.5697 1.0971 12.1666 1.83348 12.1666Z"
      fill="currentColor"
    />
  </svg>
);
