import React from 'react';
import IconBase from 'react-icons/IconBase';

export const Mail = props => (
  <IconBase
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor">
      <path d="m25.7987 8c.9804 0 1.3753 1.26415.5692 1.82219l-9.7987 6.78371c-.3424.2371-.796.2371-1.1384 0l-9.79874-6.78371c-.80606-.55803-.41116-1.82219.56921-1.82219z" />
      <path d="m20.0833 16.0833 6.9167-4.9404v10.8571c0 .2935-.0632.5722-.1768.8232z" />
      <path d="m18.8459 16.9672-2.2647 1.6176c-.3477.2484-.8147.2484-1.1624 0l-2.2647-1.6176-6.89218 6.8922c.22836.0907.4774.1406.73808.1406h18c.2607 0 .5097-.0499.7381-.1406z" />
      <path d="m11.9167 16.0833-6.73995 6.7399c-.11354-.251-.17675-.5297-.17675-.8232v-10.8571z" />
    </g>
  </IconBase>
);

export const WarningIcon = props => (
  <IconBase
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="6" cy="6" r="5.625" stroke="#F69935" strokeWidth="0.75" />
    <path
      d="M6.60354 3H5.29982L5.41701 7.20117H6.48928L6.60354 3ZM5.95315 9.07617C6.32815 9.07617 6.65334 8.7627 6.65627 8.37305C6.65334 7.98926 6.32815 7.67578 5.95315 7.67578C5.56643 7.67578 5.24709 7.98926 5.25002 8.37305C5.24709 8.7627 5.56643 9.07617 5.95315 9.07617Z"
      fill="#F69935"
    />
  </IconBase>
);
